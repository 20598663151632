import React, { useRef, useContext, useEffect, useCallback } from "react"
import { Layout } from "@components/layout"
import { Stepper } from "@components/shared"
import { CartCtx, useActions, use } from "@components/contexted"

import { OrderAndDeliveryStep, CartSummaryStep, createOrder } from "./common"

const BeforeCustomerData = (props) => {
  const stepper = useRef({ setIndex: () => {} })
  const { state } = useContext(CartCtx)
  const { pageContext, cart, customer, translationUrls } = props
  const { page } = pageContext
  const { imBusy } = cart
  const { shippings } = pageContext
  const { setShippings } = useActions(CartCtx, ["setShippings"])
  const { dispatch } = useContext(CartCtx)
  useEffect(() => {
    if (shippings) {
      dispatch({
        type: "setShippings",
        payload: shippings,
      })
    }
  }, [shippings])
  
  let selectedLanguage = "/pl"
  if(window !== "undefined"){
    selectedLanguage = localStorage.getItem("language").replace("/","")
  }
  const translations = {
    pl:{
      firstTitle:"1. Podsumowanie", 
      secondTitle:"2. Dane osobowe"
    },
    en:{
      firstTitle:"1. Summary",
      secondTitle:"2. Personal data"
    }
  }

  const discountTranslations = {
    pl:{
      couponLabel:"Mam kupon rabatowy",
      sum:"Razem:",
      couponValue:"Wartość kuponu:",
      label:"Kontynuuj",
      processingLabel:"Procesowanie zamówienia"
    },
    en:{
      couponLabel:"I have a discount coupon",
      sum:"Sum:",
      couponValue:"Coupon value:",
      label:"Continue",
      processingLabel:"Order processing"
    }
  }
  return (
    <Layout {...page} imBusy={imBusy} translationUrl={translationUrls}>
      <div className="checkout">
        <Stepper ref={stepper} withScroll={true}>
          <CartSummaryStep
            discountTranslations={discountTranslations}
            selectedLanguage={selectedLanguage}
            {...props}
            {...{ stepper }}
            title={translations[selectedLanguage].firstTitle}
          />
          <OrderAndDeliveryStep
            discountTranslations={discountTranslations}
            selectedLanguage={selectedLanguage}
            {...props}
            cart={state}
            createOrder={createOrder}
            {...{ stepper }}
            title={translations[selectedLanguage].secondTitle}
          />
        </Stepper>
        {/* <AddToCart /> */}
      </div>
    </Layout>
  )
}

export default BeforeCustomerData
