import React, { useState, useEffect, useCallback, useContext } from "react"
import { Helmet } from "react-helmet"

import { WindowCtx } from "@components/contexted"
import { AnimatedLink } from "@components/shared"
import { Cart } from "@components/ecommerce"
import { Search } from "@components/theme"
import { button__secondary } from "@styles/button.module.scss"
import Hamburger from "./Hamburger"
import Menu from "./Menu"
import en from "./icons/en.png"
import pl from "./icons/pl.png"

import { INavItem } from "./types"

import {
  header,
  header__fixed,
  logo,
  logo__white,
  logo__blue,
  nav,
  nav__list,
  nav__list__link,
  changer__wrapper
} from "./header.module.scss"
import { current } from "@reduxjs/toolkit"

const LanguageChanger: React.FC = ({translationUrl}): JSX.Element => {
  console.log('ttt', translationUrl)
  return (
    <div className={changer__wrapper}>
      <AnimatedLink
        onClick={() => { localStorage.setItem('language', '/pl')}}
        to={translationUrl.pl}>
        <img src={pl}></img>
    </AnimatedLink>
      <AnimatedLink to={translationUrl.en}
        onClick={() => { localStorage.setItem('language', '/en')}}>
        <img src={en}></img>
    </AnimatedLink>
    </div>
  )
}

const navLinks: Array<INavItem> = [
  {
    name: "blog",
    url: "/pl/blog",
  },
  {
    name: "sklep",
    url: "/pl/shop",
  },
  // {
  //   name: "jak",
  //   url: "/#jak",
  // },
  // {
  //   name: "za ile",
  //   url: "/#zaile",
  // },
  {
    name: "pogadajmy",
    url: "/#pogadajmy",
    button: true,
  },
]

const navLinksEng: Array<INavItem> = [
  {
    name: "blog",
    url: "/en/blog",
  },
  {
    name: "shop",
    url: "/en/shop",
  },
  // {
  //   name: "how",
  //   url: "/#jak",
  // },
  // {
  //   name: "pricing",
  //   url: "/#zaile",
  // },
  {
    name: "contact",
    url: "/#pogadajmy",
    button: true,
  },
]
const Header: React.FC = ({translationUrl}): JSX.Element => {
  const { y } = useContext(WindowCtx)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [language, setLanguage] = useState('')

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleKeydown = useCallback((e) => {
    if (!isMenuOpen) return
    if (e.which === 27 || e.key === "Escape") toggleMenu()
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e: Event) => handleKeydown(e))
    return () =>
      window.removeEventListener("keydown", (e: Event) => handleKeydown(e))
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      let language = localStorage.getItem('language')
      if (language === null) {
        localStorage.setItem('language', '')
      }
    }
  }, [])

  let selectedLanguage = null
  if (typeof window !== "undefined") {
    selectedLanguage = localStorage.getItem('language')
  }

  useEffect(() => {
    if (selectedLanguage) {
      setLanguage(selectedLanguage)
    }
  }, [selectedLanguage])


  return (
    <header className={y < 25 ? header : header__fixed}>
      <Helmet>
        <body className={isMenuOpen ? "blocked" : ""} />
      </Helmet>

      <nav className={nav}>
        <div className={logo}>
          <AnimatedLink
            to={`${selectedLanguage}`}
            className={isMenuOpen ? logo__white : logo__blue}
          >
            Great Gatsby Store
          </AnimatedLink>
        </div>
        {JSON.stringify(language)}
        <ul className={nav__list}>
          {language === "/pl" ? (
            navLinks.map(({ name, url, button }) => {
              const itemClass = button ? button__secondary : nav__list__link
              return (
                <li key={name}>
                  <AnimatedLink to={url} className={itemClass}>
                    {name}
                  </AnimatedLink>
                </li>
              )
            })
          ) : (
              navLinksEng.map(({ name, url, button }) => {
                const itemClass = button ? button__secondary : nav__list__link
                return (
                  <li key={name}>
                    <AnimatedLink to={url} className={itemClass}>
                      {name}
                    </AnimatedLink>
                  </li>
                )
              })
            )}
          <li>
            <LanguageChanger translationUrl={translationUrl} />
          </li>
          <li>
            <Cart className={nav__list__link} />
          </li>
          <li>
            <Search className={nav__list__link} />
          </li>
        </ul>

        <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </nav>

      <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </header>
  )
}

export default Header
