import React from "react"
import { Breadcrumbs, Reviews, Slider, Stepper } from "@components/shared"
import { FlexRow } from "@components/layout"
import {
  Description,
  Attributes,
  AddToCart,
  Tags,
  ProductAvailability,
} from "./common"
import { Layout, Parallax } from "@components/layout"

import {
  product__wrapper,
  product__inner,
  product__title,
  product__category,
  product__content,
  product__content__icon,
  product__longDescription,
  slider,
} from "./styles/product.module.scss"

const SimpleProduct = (props) => {
  const { addToCart, onGalleryClick, product, cart, page } = props
  const { productCategories } = product
  
  let selectedLanguage = "/pl"
  if(window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  
  const currentCode = selectedLanguage.replace("/", "")
  const currentCategory = productCategories.nodes.filter((el) => el.language.code === currentCode.toUpperCase())[0]
  
  return (
    <div className={product__wrapper}>
      <div className={product__inner}>
        <h6 className={product__category}>
          {currentCategory.name}
        </h6>
        <Breadcrumbs
          elements={[
            {
              label: currentCategory.name,
              url: `${selectedLanguage}/shop/categories/${currentCategory.slug}/`,
            },
            {
              label: product.name,
              url: `/shop/products/${product.slug}/`,
            },
          ]}
        />

        <div className={product__content__icon}></div>
        <div className={product__content}>
          <div>
            {product.productTags && (
              <Tags productTags={product.productTags.nodes} />
            )}

            <h1 className={product__title}>{product.name}</h1>

            {product.attributes && (
              <Attributes attributes={product.attributes.nodes} />
            )}
            <Description description={product.shortDescription} />
            <ProductAvailability stockQuantity={product.stockQuantity} />
            <AddToCart
              {...product}
              onAddToCart={(item) => {
                addToCart({
                  ...item,
                  image: product.image,
                })
              }}
            />
          </div>

          {product.galleryImages.nodes && (
            <Slider className={slider} items={product.galleryImages.nodes} />
          )}
        </div>

        <div className={product__longDescription}>
          {/* <Stepper> */}
          <Description title={"Opis"} description={product.description} />
          <Reviews
            title={"Opinie"}
            productId={product.productId}
            contextReviews={product.reviews.nodes}
          />
          {/* </Stepper> */}
        </div>
      </div>
    </div>
  )
}

export default SimpleProduct
